import { AppLayout } from '@amzn/awsui-components-react';
import React from 'react';
import { BaseStaticPage } from './BaseStaticPage';
import { content } from './CareerServicesContent';

export const CareerServices = () => {
    return <AppLayout content={BaseStaticPage(content)} toolsHide navigationHide />;
};

export default CareerServices;
