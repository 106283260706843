import React from 'react';
import { LiveComponent } from '@amzn/aws-tc-xb-xps';
import { useCourseData } from '../../context/CourseDetailContext';
import AppStrings from '../../resources/strings';
import { CourseClasses } from '../CourseClasses/CourseClasses';
import { CourseDetails } from '../CourseDetails/CourseDetails';
import { Tabs } from '../tabs/Tabs';

export const CoursePageContent = () => {
    const { coursePage: pageStrings } = AppStrings;
    const course = useCourseData()?.course;

    return (
        <Tabs
            tabs={[
                {
                    label: pageStrings.courseDetailsTabHeading,
                    id: 'details',
                    content: <CourseDetails />,
                },
                {
                    label: pageStrings.courseClassesTabHeading,
                    id: 'classes',
                    content: <CourseClasses />,
                },
                {
                    label: pageStrings.courseTrainingTabHeading,
                    id: 'outline',
                    content: (
                        <LiveComponent
                            componentName='Trainings'
                            experienceProviderName='productdetailpage'
                            props={{ ids: { catalogItemVariantId: course?.id }, flavor: 'aci' } as any}
                        />
                    ),
                },
            ]}
        />
    );
};
