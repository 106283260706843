import { Button, Container, Header, RadioGroup, SpaceBetween } from '@amzn/awsui-components-react';
import React, { Fragment, useState } from 'react';
import { CourseClassItem } from '../../common';
import { useCourseData } from '../../context/CourseDetailContext';
import { useNotifications, useSelectLearningActivityFromGroup } from '../../hooks';
import AppStrings from '../../resources/strings';
import { CourseClassDetails } from '../CourseClassDetails/CourseClassDetails';

export interface ClassSelectionProps {
    classItems: CourseClassItem[];
    preselectedClassId?: string;
    setShowClassSelection: (showClassSelection: boolean) => void;
    buttonsHidden?: boolean;
}

export const ClassSelection = ({ classItems, preselectedClassId, setShowClassSelection, buttonsHidden }: ClassSelectionProps) => {
    const { coursePage: pageStrings } = AppStrings;
    const { showSuccessMessage } = useNotifications();
    const [selectedClassId, setSelectedClassId] = useState(preselectedClassId ?? '');
    const [selectLearningActivity] = useSelectLearningActivityFromGroup(selectedClassId);
    const updateRegisteredLearningActivity = useCourseData()?.updateRegisteredLearningActivity!;

    const saveSelection = () => {
        selectLearningActivity().then((result) => {
            if (!result.errors) {
                showSuccessMessage(pageStrings.saveSuccess);
                setShowClassSelection(false);
                updateRegisteredLearningActivity(selectedClassId);
            }
        });
    };

    return (
        <Fragment>
            <SpaceBetween size='m' data-testid='class-selection'>
                <Header variant='h1'>{pageStrings.unregisteredClassesHeader}</Header>
                <p style={{ padding: '8px 0px' }}>{pageStrings.unregisteredClassesSubheader}</p>
                {classItems.map((item, index) => {
                    return (
                        <Container key={`courseClassDetails-${index}`}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Header variant='h3'>{`${pageStrings.classesOption} ${index + 1}`}</Header>
                                <RadioGroup
                                    onChange={() => {
                                        setSelectedClassId(item.id);
                                    }}
                                    value={selectedClassId}
                                    items={[{ value: item.id, label: '' }]}
                                    readOnly={buttonsHidden}
                                />
                            </div>
                            <CourseClassDetails item={item} />
                        </Container>
                    );
                })}
            </SpaceBetween>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '16px',
                }}
            >
                {!buttonsHidden && (
                    <SpaceBetween size='s' direction='horizontal'>
                        <Button
                            wrapText={false}
                            variant='normal'
                            onClick={() => {
                                setShowClassSelection(false);
                            }}
                            // Button is disabled if there is no previous selection, learner has to make initial choice first
                            disabled={!preselectedClassId}
                        >
                            {pageStrings.cancelSave}
                        </Button>

                        <Button wrapText={false} variant='primary' onClick={saveSelection}>
                            {pageStrings.confirmSave}
                        </Button>
                    </SpaceBetween>
                )}
            </div>
        </Fragment>
    );
};
