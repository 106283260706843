import { Box, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { IconProps } from '../../common/types';

export const CategoryIcon = ({ value, children, size = 'small' }: IconProps) => {
    return (
        <SpaceBetween alignItems='center' size={size == 'small' ? 'xxs' : 'xs'} direction='horizontal'>
            <Icon
                name='suggestions'
                variant={size == 'small' ? 'subtle' : 'normal'}
                size={size == 'small' ? 'small' : 'medium'}
                alt='Domain'
                data-testid='category'
            />
            <Box
                color={size == 'small' ? 'text-status-inactive' : 'inherit'}
                variant='div'
                fontSize={size == 'small' ? 'heading-xs' : 'heading-m'}
                fontWeight={size == 'small' ? 'normal' : 'bold'}
            >
                <p>{value}</p>
                {children}
            </Box>
        </SpaceBetween>
    );
};
