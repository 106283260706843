import { AppLayout } from '@amzn/awsui-components-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CoursePageContent } from '../../components/CoursePageContent/CoursePageContent';
import { CoursePageHeader } from '../../components/CoursePageHeader/CoursePageHeader';
import { CourseDetailProvider } from '../../context/CourseDetailContext';

export const Course = () => {
    const { id } = useParams<{ id: string }>();
    return (
        <AppLayout
            toolsHide
            navigationHide
            content={
                <CourseDetailProvider id={id!}>
                    <div data-testid='course-page'>
                        <CoursePageHeader />
                        <CoursePageContent />
                    </div>
                </CourseDetailProvider>
            }
        />
    );
};
