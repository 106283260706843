import {
    AnchorNavigation,
    Box,
    Button,
    Container,
    ContentLayout,
    Grid,
    Header,
    Popover,
    SpaceBetween,
    StatusIndicator,
} from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import styled from 'styled-components';

export interface StaticPageProps {
    header: string;
    headerDescription: string;
    infoBoxTitle?: string;
    infoBoxContent?: string;
    sections: {
        title: string;
        subTitle?: string;
        content: string;
    }[];
}

const Layout = styled.div`
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1152px;
`;

const PageSections = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    > * {
        &:first-child {
            border: none;
            padding-top: 0;
        }
    }
`;

const BorderedSection = styled.div`
    border-top: 2px solid #b6bec9;
    padding: 2rem 0;
`;

export const BaseStaticPage = (props: StaticPageProps) => {
    useEffect(() => {
        if (window && window?.location?.hash) {
            const hashElement = document.getElementById(window.location.hash.slice(1));
            if (hashElement) {
                hashElement.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }
    }, []);

    const copyLink = (title: string) => {
        if (window) {
            /** Removing the existing hash from the URL while setting the copy link text */
            const windowLocation = window.location.href.split(window.location.hash || '#')[0];
            const pageHash = title.replaceAll(' ', '');
            navigator?.clipboard.writeText?.(`${windowLocation}#${pageHash}`);
        }
    };
    return (
        <Layout>
            <ContentLayout
                defaultPadding
                header={
                    <Header variant='h1' description={props.headerDescription}>
                        {props.header}
                    </Header>
                }
            >
                <Box margin={{ top: 'xxl' }}>
                    <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
                        <SpaceBetween size='xxl'>
                            {props.infoBoxTitle && props.infoBoxContent && (
                                <Container>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <img src='/public/robot.svg' width='200' />
                                        </div>
                                        <div style={{ display: 'flex', gap: '8px', flexDirection: 'column', paddingLeft: '1rem' }}>
                                            <Header variant='h2'>{props.infoBoxTitle}</Header>
                                            <p>{props.infoBoxContent}</p>
                                        </div>
                                    </div>
                                </Container>
                            )}
                            <PageSections>
                                {props.sections.map(({ title, subTitle, content }) => {
                                    return (
                                        <BorderedSection key={title} id={title.replaceAll(' ', '')}>
                                            <SpaceBetween size='xl' key={title}>
                                                <SpaceBetween alignItems='end' direction='horizontal' size='xxs'>
                                                    <Popover
                                                        triggerAriaLabel='copy heading link'
                                                        dismissButton={false}
                                                        position='top'
                                                        size='small'
                                                        triggerType='custom'
                                                        content={<StatusIndicator type='success'>URL copied</StatusIndicator>}
                                                    >
                                                        <Button
                                                            variant='inline-link'
                                                            iconName='anchor-link'
                                                            ariaLabel='copy heading link'
                                                            onClick={(e) => copyLink(title)}
                                                        />
                                                    </Popover>
                                                    <Header variant='h2'>{title}</Header>
                                                </SpaceBetween>
                                                {subTitle && <Header variant='h3'>{subTitle}</Header>}
                                                <p>{content}</p>
                                            </SpaceBetween>
                                        </BorderedSection>
                                    );
                                })}
                            </PageSections>
                        </SpaceBetween>
                        <div>
                            <Header variant='h3'>
                                <span>On this page</span>
                            </Header>
                            <AnchorNavigation
                                anchors={props.sections.map((section) => {
                                    return {
                                        text: section.title,
                                        href: `#${section.title.replaceAll(' ', '')}`,
                                        level: 1,
                                    };
                                })}
                            />
                        </div>
                    </Grid>
                </Box>
            </ContentLayout>
        </Layout>
    );
};
