import { AppLayout } from '@amzn/awsui-components-react';
import React from 'react';
import { BaseStaticPage } from './BaseStaticPage';
import { content } from './LearnerHandbookContent';

export const LearnerHandbook = () => {
    return <AppLayout content={BaseStaticPage(content)} toolsHide navigationHide />;
};

export default LearnerHandbook;
