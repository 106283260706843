import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { formatDate } from './calendarUtils';
import { Event } from './Event';
import { EventType } from '../../common';

export const CalendarList = ({ visibleDays, filterEvents }: { visibleDays: Date[]; filterEvents: (day: Date) => EventType[] }) => {
    return (
        <div className='list'>
            <div className='centered'>
                <SpaceBetween size='l'>
                    {visibleDays.map((day, dayIndex) => {
                        return (
                            <div key={`day-${dayIndex}`}>
                                <b>{formatDate(day)}</b>
                                {filterEvents(day).map((event, hourIndex) => (
                                    <div key={`event-${dayIndex}-${hourIndex}`} style={{ margin: '10px 0' }}>
                                        <Event event={event} listView={true} />
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </SpaceBetween>
            </div>
        </div>
    );
};
