import React from 'react';
import { EventType } from '../../common';
import { Link, Popover, SpaceBetween } from '@amzn/awsui-components-react';
import { CategoryIcon, DurationIcon } from '../icons';
import './Event.scss';
import { formatTime, getEventStartHour } from './calendarUtils';
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';

export interface EventProps {
    event: EventType;
    listView?: boolean;
}

export const Event = ({ event, listView }: EventProps) => {
    return (
        <Popover
            className='popover'
            header={event.title}
            content={
                <SpaceBetween size='m'>
                    <DurationIcon
                        value={`${formatTime(getEventStartHour(event), event.date.getMinutes())} - ${event.durationInMinutes} minutes`}
                    />
                    <CategoryIcon>
                        <>
                            Part of <NavLink to={`/course/${event.learningActivityGroupId}`}>{event.catalogItemName}</NavLink>
                        </>
                    </CategoryIcon>
                    <>{parse(event.description)}</>
                    <Link href={event.url}>Join event</Link>
                </SpaceBetween>
            }
        >
            <div
                className={`event ${event.color}`}
                style={{
                    height: listView ? 60 : event.durationInMinutes + (event.durationInMinutes / 60 - 1) * 2,
                    position: 'relative',
                    top: listView ? 0 : event.date.getMinutes(),
                }}
                data-testid='event'
            >
                <div>
                    <b>{event.title}</b>
                    <p style={{ margin: 0 }}>
                        {formatTime(getEventStartHour(event), event.date.getMinutes())} - {event.durationInMinutes} minutes
                    </p>
                </div>
            </div>
        </Popover>
    );
};
