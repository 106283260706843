import React, { useState } from 'react';
import { Tabs as AwsuiTabs, TabsProps } from '@amzn/awsui-components-react';
import { useRouter } from '@amzn/xb-hooks';

export const Tabs = ({ tabs }: { tabs: TabsProps.Tab[] }) => {
    const { useSearchParams, setSearchParams } = useRouter();
    const searchParams = useSearchParams();
    const [activeTab, setActiveTabId] = useState(searchParams.get('activeTab') ?? tabs[0].id);

    return (
        <div data-testid='course-content' style={{ margin: 'auto', maxWidth: '1152px', paddingBottom: '3rem' }}>
            <AwsuiTabs
                activeTabId={activeTab}
                onChange={(event) => {
                    setActiveTabId(event.detail.activeTabId);
                    setSearchParams({ activeTab: event.detail.activeTabId });
                }}
                tabs={tabs}
            />
        </div>
    );
};
