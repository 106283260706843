import React from 'react';
import { formatDate, formatTime, visibleHours } from './calendarUtils';
import { Event } from './Event';

export const CalendarGrid = ({ events, visibleDays }: { events: any[][]; visibleDays: Date[] }) => {
    return (
        <table className='table'>
            <thead style={{ display: 'block' }}>
                <tr>
                    <th style={{ width: '40px' }}></th>
                    {visibleDays.map((day, index) => (
                        <th key={index}>{formatDate(day)}</th>
                    ))}
                </tr>
            </thead>
            <tbody style={{ height: '400px', overflowY: 'scroll', display: 'block' }}>
                <tr style={{ height: '10px' }}></tr>
                {events.map((hour, hourIndex) => {
                    return (
                        <tr className='row' key={`row-${hourIndex}`}>
                            <th>{formatTime(visibleHours[hourIndex])}</th>
                            {hour.map((event, eventIndex) => {
                                if (event.skip) return null;
                                if (event.empty) {
                                    return <td className='cell' key={`cell-${hourIndex}-${eventIndex}`}></td>;
                                }
                                return (
                                    <td
                                        className='cell'
                                        rowSpan={Math.ceil(event.durationInMinutes / 60)}
                                        key={`cell-${hourIndex}-${eventIndex}`}
                                    >
                                        <Event event={event} />
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
