import { gql, useMutation } from '@apollo/client';
import { useUserInfo } from '../user';
import { useNotifications } from '../notifications/useNotifications';
import { getErrorMessageFromErrorCode } from '@amzn/aws-tc-ilt-common';

export const SELECT_LEARNING_ACTIVITY = gql`
    mutation selectLearningActivityFromGroup($userId: ID!, $learningActivityId: ID!) {
        selectLearningActivityFromGroup(userIds: [$userId], learningActivityId: $learningActivityId) {
            registrationStatus
        }
    }
`;

export const useSelectLearningActivityFromGroup = (learningActivityId: string) => {
    const { user } = useUserInfo();
    const { showErrorMessage } = useNotifications();
    return useMutation(SELECT_LEARNING_ACTIVITY, {
        variables: { userId: user.id, learningActivityId: learningActivityId },
        onError: (error) => {
            showErrorMessage(getErrorMessageFromErrorCode(error.message));
        },
    });
};
