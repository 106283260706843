export const content = {
    header: 'Quick Start Guide',
    headerDescription: 'Last updated April 4, 2024',
    infoBoxTitle: 'How to use this guide',
    infoBoxContent:
        'Discover the key steps to start learning with AWS Cloud Institute, including how to create your schedule, join webinars, and connect with instructors and classmates. Can’t find what you need? The Learner Handbook covers in-depth program information, including course overviews, billing, and enrollment. You can also find answers to the most frequently asked questions in our FAQ',
    sections: [
        {
            title: 'Choosing your class',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
            title: 'Changing your class',
            href: 'ChangingYourClass',
            content:
                'You have the option to enroll as a full-time or part-time learner, and may update that status at any time during your participation in the program. A full-time learner is someone who is registered for three courses in any given quarter. A learner who enrolls in, and completes, three courses for four-consecutive quarters is expected to finish the program in 12 months. A part-time learner is someone who is enrolled in less than three courses per quarter. A part-time learner can finish the program in 36 months or less.\n' +
                'Register for courses prior to the start of each quarter by selecting the number of courses you plan on taking (one, two, or three courses), and then reviewing the prescriptive courses that are assigned to you based on your course load selection.\n' +
                'After selecting your courses, proceed to check out where you will view your order summary with a prompt to access or connect to your AWS account. Once your account is connected, you will be able to review enrollment and billing summary and complete registration.',
        },
        {
            title: 'Joining a live training',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
            title: 'Watching a recorded training',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
            title: 'Completing curriculum',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
            title: 'Using Slack',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    ],
};
