export const DEFAULT_ROUTE = '/';
export const HOME_ROUTE = '/home';
export const COURSE_DETAIL_ROUTE = '/course';
export const TRAINING_MODULE_DETAIL_ROUTE = '/training-module';
export const QUICK_START_ROUTE = '/quickstart';
export const CAREER_SERVICES_ROUTE = '/career-services';
export const LEARNER_HANDBOOK_ROUTE = '/learner-handbook';
export const FAQ_ROUTE = '/faq';
export const TRANSCRIPT_ROUTE = '/transcript';
export const ACCESS_DENIED_ROUTE = '/accessdenied';
export const PAGE_NOT_FOUND_ROUTE = '/notFound';
export const MATCH_ALL_ROUTE = '*';
