import { enums } from '@amzn/sbux-lib';
import { CourseDetails } from '../../context/CourseDetailContext';
import { AciCourseLoadTranscriptStatus, AciTranscript, DeliverySession, LearningActivityGroup, RegistrationStatus } from '../types';
import { MILLISECONDS } from '../constants';

export const getCourseContextFromTranscript = (data: AciTranscript[], lagId: string): CourseDetails | undefined => {
    const transcriptData = data.find((transcript: AciTranscript) => transcript.learningActivityGroup.pk === lagId);
    return (
        transcriptData && {
            aciTranscriptStatus: transcriptData.aciTranscriptStatus,
            aciCompletionDate: transcriptData.completionDate ? new Date(transcriptData.completionDate) : undefined,
            ...(transcriptData.digitalTraining && {
                digitalTraining: {
                    numberOfCompletedCourses: transcriptData?.digitalTraining?.numberOfCompletedCourses,
                    courseDetails: transcriptData?.digitalTraining?.courseDetails,
                },
            }),
            actionAreaStatus: convertAciTranscriptToActionAreaStatus(transcriptData),
            course: transcriptData.learningActivityGroup.catalog_item,
            joinSessionUrl: getJoinSessionUrl(transcriptData.registration?.learningActivity?.delivery_sessions),
            learningActivityGroup: transcriptData.learningActivityGroup,
            registration: transcriptData.registration,
        }
    );
};

export const getCourseContextFromLAG = (learningActivityGroup: LearningActivityGroup) => {
    return {
        aciTranscriptStatus: AciCourseLoadTranscriptStatus.NOT_STARTED,
        actionAreaStatus: enums.States.NOT_REGISTERED,
        course: learningActivityGroup?.catalog_item,
        learningActivityGroup: learningActivityGroup,
    };
};

export const getJoinSessionUrl = (deliverySessions?: DeliverySession[]) => {
    if (!deliverySessions) return undefined;
    // Date.now() returns milliseconds, but we need the timestamp in seconds to compare dates
    const currentTimestamp = Date.now() / MILLISECONDS;
    // Find the earliest session that has not ended yet
    const nextSession = deliverySessions
        .filter((session) => currentTimestamp < session.end_timestamp)
        .sort((session1, session2) => session1.start_timestamp - session2.start_timestamp);
    return nextSession[0]?.v_ilt_info?.url;
};

const convertAciTranscriptToActionAreaStatus = (aciTranscript: AciTranscript) => {
    const { aciTranscriptStatus, registration } = aciTranscript;

    /**
     * RegistrationStatus `WITHDRAWN` - Action area status `NOT_REGISTERED`
     *
     * Else
     *
     * TranscriptStatus `COMPLETED` - `TROPHY`
     * TranscriptStatus `IN_PROGRESS` - `IN_PROGRESS` if events are registered, else `NOT_ENROLLED`
     * TranscriptStatus `NOT_STARTED` - `ENROLLED` if events are registered, else `NOT_ENROLLED`
     * TranscriptStatus `INCOMPLETE` - `NOT_REGISTERED` Since the quarter is finished
     *
     */

    if (registration?.registrationStatus === RegistrationStatus.WITHDRAWN) {
        return enums.States.NOT_REGISTERED;
    }

    const enrolledEvents = registration?.learningActivity; // If null, then not_enrolled, need to make sure we dont get empty object
    switch (aciTranscriptStatus) {
        case AciCourseLoadTranscriptStatus.COMPLETED:
            return enums.States.TROPHY;

        case AciCourseLoadTranscriptStatus.INCOMPLETE:
            // TODO - Need to verfify this. Since incomplete reflects that the quarter is over, should it be not_registered OR in_progress?
            return enums.States.NOT_REGISTERED;

        case AciCourseLoadTranscriptStatus.NOT_STARTED: {
            return enrolledEvents ? enums.States.ENROLLED : enums.States.NOT_ENROLLED;
        }

        case AciCourseLoadTranscriptStatus.IN_PROGRESS: {
            return enrolledEvents ? enums.States.IN_PROGRESS : enums.States.NOT_ENROLLED;
        }

        default:
            return enums.States.IN_PROGRESS;
    }
};

export const convertDigitalTranscriptStatus = (status?: string) => {
    switch (status) {
        case 'COMPLETED':
            return enums.States.COMPLETE;
        case 'NOT_STARTED':
            return enums.States.ENROLLED;
        case 'IN_PROGRESS':
            return enums.States.IN_PROGRESS;
        default:
            return enums.States.NOT_ENROLLED;
    }
};
