import { AppLayout } from '@amzn/awsui-components-react';
import React from 'react';
import { BaseStaticPage } from './BaseStaticPage';
import { content } from './QuickStartContent';

export const QuickStart = () => {
    return <AppLayout content={BaseStaticPage(content)} toolsHide navigationHide />;
};

export default QuickStart;
