import React from 'react';
import AppStrings from '../../resources/strings';
import { SegmentedControl } from '@amzn/awsui-components-react';
import { addDays, getPreviousMonday } from './calendarUtils';

export const WeekSelector = ({ week, setWeek }: { week: Date; setWeek: (date: Date) => void }) => {
    const {
        dashboardPage: { calendar },
    } = AppStrings;

    return (
        <SegmentedControl
            selectedId='none'
            options={[
                { text: '', id: 'previous', iconName: 'caret-left-filled' },
                { text: calendar.today, id: 'today' },
                { text: '', id: 'next', iconName: 'caret-right-filled' },
            ]}
            onChange={({ detail }) => {
                if (detail.selectedId === 'previous') {
                    setWeek(addDays(week, -7));
                } else if (detail.selectedId === 'next') {
                    setWeek(addDays(week, 7));
                } else if (detail.selectedId === 'today') {
                    setWeek(getPreviousMonday(new Date()));
                }
            }}
            data-testid='week-selector'
        />
    );
};
