export const config = {
    default: {
        cognito: {
            IdentityPoolId: 'us-east-1:2bd1b78b-b38a-485c-8ea2-b2664451ab94',
            region: 'us-east-1',
        },
        evidently: {
            region: 'us-east-1',
            endpoint: 'https://evidently.us-east-1.amazonaws.com',
        },
        sts: {
            region: 'us-east-1',
            unauthRole: 'arn:aws:iam::843378256785:role/AT2Experiments-Gamma-Comm-GammaAT2ExperimentsCogni-ES4QVUTOGD4G',
            roleSessionName: 'evidently',
        },
        apolloAppUri: 'https://beta.awscloudinstitute.skillbuilder.training.aws.dev/graphql',
    },
    local: {
        cognito: {
            IdentityPoolId: 'us-east-1:2bd1b78b-b38a-485c-8ea2-b2664451ab94',
            region: 'us-east-1',
        },
        evidently: {
            region: 'us-east-1',
            endpoint: 'https://evidently.us-east-1.amazonaws.com',
        },
        apolloAppUri: 'http://localhost:3000/graphql',
    },
    beta: {
        cognito: {
            IdentityPoolId: 'us-east-1:2bd1b78b-b38a-485c-8ea2-b2664451ab94',
            region: 'us-east-1',
        },
        evidently: {
            region: 'us-east-1',
            endpoint: 'https://evidently.us-east-1.amazonaws.com',
        },
        apolloAppUri: 'https://beta.awscloudinstitute.skillbuilder.training.aws.dev/graphql',
    },
    gamma: {
        cognito: {
            IdentityPoolId: 'us-east-1:2bd1b78b-b38a-485c-8ea2-b2664451ab94',
            region: 'us-east-1',
        },
        evidently: {
            region: 'us-east-1',
            endpoint: 'https://evidently.us-east-1.amazonaws.com',
        },
        apolloAppUri: 'https://awscloudinstitute.skillbuilder.training.aws.dev/graphql',
    },
};
