import { gql, useQuery } from '@apollo/client';
import { useNotifications } from '../notifications/useNotifications';

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            id
            emailAddress
            firstName
            lastName
            userRole
            groups {
                nodes {
                    name
                }
            }
            preferences {
                timezone
            }
        }
    }
`;

export const useGetCurrentUser = () => {
    const { showErrorMessage } = useNotifications();
    return useQuery(GET_CURRENT_USER, {
        onError: (error) => {
            showErrorMessage(error.message);
        },
    });
};
