import { enums, MetadataItem, ProductCard } from '@amzn/sbux-lib';
import { Locales, useRouter } from '@amzn/xb-hooks';
import React from 'react';
import { APPLICATION_NAME, ENROLLMENT_IS_OPEN, QUARTER_DATES } from '../constants';
import { COURSE_DETAIL_ROUTE } from '../routes';
import { AciTranscript, SkillLevel } from '../types';
import { convertSecondsToHoursMinutes } from './dateUtils';

export const getWeekDay = (date: Date) => {
    const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return WEEKDAYS[date.getDay()];
};

export const createList = (items?: string[]) => {
    if (!items || !items.length) {
        return null;
    }
    return (
        <ul>
            {items.map((item) => (
                <li key={item}>{item}</li>
            ))}
        </ul>
    );
};

export const productCardFromTranscript = (transcript: AciTranscript) => {
    const course = transcript.learningActivityGroup.catalog_item;
    const { push } = useRouter();
    return (
        <ProductCard
            key={transcript.id}
            type={APPLICATION_NAME}
            href={`${COURSE_DETAIL_ROUTE}/${transcript.learningActivityGroup.pk}`}
            duration={course.duration.value}
            name={course.name}
            language={getDisplayNameFromLanguageCode(course.languageCode)}
            childrenCount={course.children?.nodes?.length}
            skillLevel={course.skillLevel?.valueDisplayString ?? SkillLevel.Fundamental}
            transcriptStatus={transcript.aciTranscriptStatus}
            domain={course.domain?.valueDisplayString}
            awsServices={course.awsServices?.valuesDisplayStrings}
            onClick={(e, href) => {
                e.preventDefault();
                push(href!);
            }}
        />
    );
};

export const isAciEnrollmentOpen = (date: Date): boolean => {
    const mostRecentDate: string | undefined = Object.keys(ENROLLMENT_IS_OPEN)
        .filter((enrollmentDate) => new Date(enrollmentDate) <= date)
        .sort()
        .pop();
    if (!mostRecentDate) {
        return false; // Default to false if date mismatch
    }
    return ENROLLMENT_IS_OPEN[mostRecentDate];
};

export const isAciEnrollmentOpenNow = (): boolean => {
    return isAciEnrollmentOpen(new Date());
};

/**
 *
 * @param quarterDetails - array that contains the start and end date of the quarter
 * @param startDate - date that needs to be validated if it falls within a quarter
 * @param endDate (optional) - end date that needs to be validated
 * @returns {boolean} if the date falls within the passed quarter
 */
export const isWithinQuarter = ([quarterStartDate, quarterEndDate]: [string, string], startDate: Date, endDate?: Date) => {
    /** validate if the start date falls between the quarter start and end dates if end date is not provided
     * provided start date should be greater than the quarter start date
     * prodived end date OR fallback to startDate should be sooner than the quarter end date
     */
    return startDate >= new Date(quarterStartDate) && (endDate ?? startDate) <= new Date(quarterEndDate);
};

/**
 * getCurrentQuarterIndex - returns the index for the current quarter
 * @returns {number} index of the current quarter
 */
export const getCurrentQuarterIndex = () => {
    return QUARTER_DATES.findIndex((quarterDates: string[]) => {
        const [startDate, endDate] = quarterDates;
        const today = new Date();

        /** today falls perfectly between the start and end date of the defined quarters */
        const isCurrentQuarter = isWithinQuarter(quarterDates as [string, string], today);

        /**
         * There is usually a difference of a day between when the quarter ends and the next quarter.
         * check if it is within 2 days of the end of quarter to treat that as the current quarter
         */
        if (!isCurrentQuarter) {
            const timeDiff = today.getTime() - new Date(endDate).getTime();
            const dateDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
            return today >= new Date(startDate) && dateDiff < 2;
        }
        return isCurrentQuarter;
    });
};

export const getCurrentQuarter = () => {
    const quarterIndex = getCurrentQuarterIndex();
    return quarterIndex >= 0 ? QUARTER_DATES[quarterIndex] : [];
};

export const generatePageHeaderMetaData = (durationInSeconds?: number, languageCode?: string): MetadataItem[] => {
    return [
        {
            type: 'text',
            text: 'AWS Cloud Institute',
            ariaLabel: 'Course Type',
        },
        {
            type: 'text',
            text: `${convertSecondsToHoursMinutes(durationInSeconds ?? 0)}`,
            ariaLabel: 'Duration',
        },
        {
            type: 'text',
            text: getDisplayNameFromLanguageCode(languageCode),
            ariaLabel: 'Language',
        },
    ];
};

export const generateStringArgs = (
    actionAreaStatus: enums.States,
    digitalTraining?: { courseDetails: string[]; numberOfCompletedCourses: number },
    aciCompletionDate?: Date,
) => {
    if (actionAreaStatus === enums.States.IN_PROGRESS && digitalTraining) {
        return {
            completedTrainings: digitalTraining.numberOfCompletedCourses.toString(),
            totalTrainings: digitalTraining.courseDetails.length.toString(),
        };
    } else if (actionAreaStatus === enums.States.TROPHY && aciCompletionDate) {
        return {
            completionDate: aciCompletionDate.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            }),
        };
    }
};

export const getDisplayNameFromLanguageCode = (languageCode?: string) => {
    return (Locales.asList().find((locale) => locale.code === languageCode) ?? Locales.ENGLISH).displayName;
};
