import React, { createContext, useContext, useEffect, useState } from 'react';

import { AciTranscript, getCourseContextFromTranscript } from '../common';
import { useAciTranscriptsByUserId, useUserInfo } from '../hooks';
import { CourseDetails } from './CourseDetailContext';

export interface AciTranscripts {
    transcripts: AciTranscript[];
    loading?: boolean;
    getCourseData: (id: string) => CourseDetails | undefined;
    updateRegisteredLearningActivity: (id: string) => void;
}

export const TranscriptContext = createContext<AciTranscripts>({
    transcripts: [],
    getCourseData: () => undefined,
    updateRegisteredLearningActivity: () => {},
});

export const useTranscriptData = () => useContext(TranscriptContext);

export const TranscriptProvider = ({ children }: { children: React.ReactNode }) => {
    const { data, loading } = useAciTranscriptsByUserId();
    const { user } = useUserInfo();
    const [transcripts, setTranscripts] = useState<AciTranscript[]>([]);

    useEffect(() => {
        if (data?.aciTranscriptsByUserId) {
            const transcriptsWithTimezone = data.aciTranscriptsByUserId.map((transcript: AciTranscript) => ({
                ...transcript,
                timezone: user.timezone!,
            }));
            setTranscripts(transcriptsWithTimezone);
        }
    }, [data, user]);

    const getCourseData = (id: string) => {
        return getCourseContextFromTranscript(transcripts, id);
    };

    const updateRegisteredLearningActivity = (learningActivityId: string) => {
        const transcript = transcripts.find((transcript) =>
            transcript.learningActivityGroup.learning_activities?.some((learningActivity) => learningActivity.pk === learningActivityId),
        );
        const transcriptId = transcript?.id;
        const registration = transcript?.registration;
        const learningActivities = transcript?.learningActivityGroup.learning_activities;
        const learningActivity = learningActivities?.find((learningActivity) => learningActivity.pk === learningActivityId);
        if (transcript && registration && learningActivity) {
            setTranscripts(
                transcripts.map((transcript) =>
                    transcript.id === transcriptId
                        ? { ...transcript, registration: { ...registration, learningActivity: learningActivity } }
                        : transcript,
                ),
            );
        }
    };

    return (
        <TranscriptContext.Provider value={{ transcripts, loading, getCourseData, updateRegisteredLearningActivity }}>
            {children}
        </TranscriptContext.Provider>
    );
};
