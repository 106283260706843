import React from 'react';
import { Box, Spinner } from '@amzn/awsui-components-react';
import styled from 'styled-components';

const StyledBox = styled(Box)`
    margin: 0;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const StyledSpinner = styled(Spinner)`
    margin-right: 5px;
`;

export const LoadingPage = () => {
    // TODO: Use official SB Spinner once it's available in SBUX
    return (
        <StyledBox fontWeight='light'>
            <StyledSpinner variant='disabled' />
            Loading...
        </StyledBox>
    );
};
