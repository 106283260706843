import React from 'react';
import { LiveComponent } from '@amzn/aws-tc-xb-xps';
import { TopNavigation } from '@amzn/awsui-components-react';

export const PageHeader: React.FC = () => {
    return (
        <LiveComponent
            data-testid='globalnav'
            experienceProviderName='globalnav'
            componentName='ACINav'
            fallback={
                /** empty nav till the header loads from the XPS */
                <TopNavigation
                    identity={{
                        href: '/',
                    }}
                />
            }
        />
    );
};
