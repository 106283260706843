import { Grid } from '@amzn/awsui-components-react';
import { ActionArea, CourseHeaderV2 as CourseHeader } from '@amzn/sbux-lib';
import React from 'react';
import { generatePageHeaderMetaData, generateStringArgs, getString } from '../../common';
import { useCourseData } from '../../context/CourseDetailContext';
import AppStrings from '../../resources/strings';

export const CoursePageHeader = () => {
    const coursePage = AppStrings.coursePage;
    const courseDetails = useCourseData()?.course;
    const actionAreaStatus = useCourseData()?.actionAreaStatus;
    const joinSessionUrl = useCourseData()?.joinSessionUrl;
    const actionAreaStrings = actionAreaStatus ? coursePage.headerActionArea[actionAreaStatus] : '';
    const digitalTraining = useCourseData()?.digitalTraining;
    const aciCompletionDate = useCourseData()?.aciCompletionDate;
    const hideActionArea = !useCourseData()?.registration;

    const joinSessionClickHandler = () => {
        window.open(joinSessionUrl, '_blank');
    };

    return (
        <div
            data-testid='course-page-header'
            style={{
                display: 'flex',
                padding: '28px 0',
                justifyContent: 'center',
            }}
        >
            <div style={{ maxWidth: '1152px', width: '100%' }}>
                {/* TODO - Need error handling */}
                {courseDetails && actionAreaStatus && actionAreaStrings && (
                    <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                        <CourseHeader
                            courseTitle={courseDetails.name}
                            progress='in_progress'
                            selectedLanguage={courseDetails.languageCode}
                            metadataItems={generatePageHeaderMetaData(courseDetails?.duration.value, courseDetails?.languageCode)}
                        />
                        {!hideActionArea && (
                            <ActionArea
                                state={actionAreaStatus}
                                heading={actionAreaStrings.headerTitle}
                                description={getString(
                                    actionAreaStrings.description,
                                    generateStringArgs(actionAreaStatus, digitalTraining, aciCompletionDate),
                                )}
                                {...(actionAreaStrings.actionLabel && {
                                    primaryButton: {
                                        ariaLabel: actionAreaStrings.actionLabel,
                                        buttonLabel: actionAreaStrings.actionLabel,
                                        onClick: joinSessionClickHandler,
                                    },
                                })}
                            />
                        )}
                    </Grid>
                )}
            </div>
        </div>
    );
};
