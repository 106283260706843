import { ColumnLayout } from '@amzn/awsui-components-react';
import { CourseClassItem } from '../../common';
import React, { Fragment } from 'react';
import AppStrings from '../../resources/strings';

export interface CourseClassDetailsProps {
    item: CourseClassItem;
}

export const CourseClassDetails = ({ item }: CourseClassDetailsProps) => {
    const { coursePage: pageStrings } = AppStrings;

    return (
        <ColumnLayout data-testid='course-class-details' columns={3}>
            <div>
                <b>{pageStrings.classesInstructors}</b>
            </div>
            <div>
                <b>{pageStrings.classesSchedule}</b>
            </div>
            <span></span>
            <div data-testid='course-class-instructors'>{item.instructors.join(', ')}</div>
            {item.schedule.map((scheduleDetails, idx) => {
                return (
                    <Fragment key={idx}>
                        {idx !== 0 && <span></span>}
                        <div>{scheduleDetails.recurrenceDay}</div>
                        <div>{scheduleDetails.recurrenceTime}</div>
                    </Fragment>
                );
            })}
        </ColumnLayout>
    );
};
