import { StaticPageProps } from './BaseStaticPage';

export const content: StaticPageProps = {
    header: 'Learner Handbook',
    headerDescription: 'Last updated April 4, 2024',
    infoBoxTitle: 'How to use this handbook',
    infoBoxContent:
        'This handbook covers in-depth program information, including course overviews,billing, and enrollment. Can’t find what you need? Check out the Quick startguide to discover the key steps to start learning with AWS Cloud Institute. Youcan also find answers to the most frequently asked questions in our FAQ.',
    sections: [
        {
            title: 'Enrollment, billing and payment',
            subTitle: 'Enrollment and course registration',
            content:
                'You have the option to enroll as a full-time or part-time learner,\n' +
                'and may update that status at any time during your participation in\n' +
                'the program. A full-time learner is someone who is registered for\n' +
                'three courses in any given quarter. A learner who enrolls in, and\n' +
                'completes, three courses for four-consecutive quarters is expected\n' +
                'to finish the program in 12 months. A part-time learner is someone\n' +
                'who is enrolled in less than three courses per quarter. A part-time\n' +
                'learner can finish the program in 36 months or less.\n' +
                'Register for courses prior to the start of each quarter by selecting\n' +
                'the number of courses you plan on taking (one, two, or three\n' +
                'courses), and then reviewing the prescriptive courses that are\n' +
                'assigned to you based on your course load selection.\n' +
                'After selecting your courses, proceed to check out where you will\n' +
                'view your order summary with a prompt to access or connect to your\n' +
                'AWS account. Once your account is connected, you will be able to\n' +
                'review enrollment and billing summary and complete registration.\n' +
                'If you fail to complete a course and wish to continue the program,\n' +
                'you will need to retake the failed course during your next quarter.\n' +
                'If you registered for courses prior to the end of the quarter and\n' +
                'then fail to complete a course, you will need to update your\n' +
                'registration during the break period to include the repeated course.\n' +
                'We’ll send you an email notifying you that you need to make this\n' +
                'change at the start of the break period.',
        },
        {
            title: 'Program cost',
            content:
                'You have the option to enroll as a full-time or part-time learner, and may update that status at any time during your participation in the program. A full-time learner is someone who is registered for three courses in any given quarter. A learner who enrolls in, and completes, three courses for four-consecutive quarters is expected to finish the program in 12 months. A part-time learner is someone who is enrolled in less than three courses per quarter. A part-time learner can finish the program in 36 months or less.\n' +
                'Register for courses prior to the start of each quarter by selecting the number of courses you plan on taking (one, two, or three courses), and then reviewing the prescriptive courses that are assigned to you based on your course load selection.\n' +
                'After selecting your courses, proceed to check out where you will view your order summary with a prompt to access or connect to your AWS account. Once your account is connected, you will be able to review enrollment and billing summary and complete registration.',
        },
        {
            title: 'Learner grants',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
            title: 'Courses overview',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
            title: 'Virtual classroom technology',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
            title: 'Career services',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    ],
};
