import { Button, Container, Header, StatusIndicator } from '@amzn/awsui-components-react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import parse from 'html-react-parser';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { BroadcastItem } from '../../common';

const StyledFlexContainer = styled.div`
    display: flex;
    position: relative;
`;

const RecordingDetails = styled.div`
    margin-left: ${awsui.spaceStaticM};

    h2 {
        color: #0972d3;
    }

    .recordingmetadata {
        margin-top: ${awsui.spaceStaticXs};
        font-weight: 700;
        color: black;

        .recordingdate {
            margin-right: ${awsui.spaceContainerHorizontal};
        }
    }

    .recordingdescription {
        margin-top: ${awsui.spaceStaticXs};
    }
`;

const StyledPlayIconContainer = styled.div`
    background-color: #e9ebed;
    border-radius: 5px;
    height: 28px;
    width: 28px;
    text-align: center;
    position: relative;
`;

const StyledIsWatched = styled(StatusIndicator)`
    position: absolute;
    right: 10px;
    top: 5px;
`;

export interface CourseRecordingCardProps {
    index: number;
    item: BroadcastItem;
}

export const CourseRecordingCard = ({ index, item }: CourseRecordingCardProps) => {
    return (
        <Container key={index}>
            <StyledFlexContainer>
                <StyledPlayIconContainer>
                    <Button variant='icon' iconName='caret-right-filled' href={item.recordingUrl} target='_blank' />
                </StyledPlayIconContainer>
                <RecordingDetails>
                    <Header variant='h2'>{item.title}</Header>
                    <div className='recordingmetadata'>
                        <span className='recordingdate'>{moment(item.date).format('MMMM DD, YYYY')}</span>
                        <StatusIndicator type='pending'>{item.duration} minutes</StatusIndicator>
                    </div>
                    <div className='recordingdescription'>{parse(item.description)}</div>
                </RecordingDetails>
                {item.watched && <StyledIsWatched type='success'>Watched</StyledIsWatched>}
            </StyledFlexContainer>
        </Container>
    );
};
